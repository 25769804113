import React from 'react';
import { FiPhoneCall, FiUser } from 'react-icons/fi';

const AppointmentSection = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-5xl md:text-7xl font-bold text-yellow-800 mb-4 animate__animated animate__fadeInRight">
            Book An Appointment
          </h2>
          <p className="text-lg text-gray-700 mb-8">
            Need advice from an expert astrologer? Get an appointment today!
          </p>
        </div>
        <div className="grid gap-8 md:grid-cols-2">
          {/* Card 1: Timing For Call & Video Chat */}
          <div className="bg-white rounded-lg shadow-lg p-6 text-center transform hover:scale-105 transition-transform">
            <div className="flex justify-center mb-4">
              <div className="bg-red-500 text-white rounded-full p-4">
                <FiPhoneCall className="w-8 h-8" />
              </div>
            </div>
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">Timing For Call & Video Chat</h3>
            <div className="mb-4">
              <p className="text-gray-700 mb-2">For India:</p>
              <p className="text-gray-700">9:00 AM To 8:00 PM</p>
            </div>
            <div>
              <p className="text-gray-700 mb-2">For Foreign Country:</p>
              <p className="text-gray-700">Anytime 24/7 Hours</p>
            </div>
          </div>
          {/* Card 2: Personal Meeting */}
          <div className="bg-white rounded-lg shadow-lg p-6 text-center transform hover:scale-105 transition-transform">
            <div className="flex justify-center mb-4">
              <div className="bg-red-500 text-white rounded-full p-4">
                <FiUser className="w-8 h-8" />
              </div>
            </div>
            <h3 className="text-2xl font-semibold text-gray-900 mb-4">Personal Meeting</h3>
            <p className="text-gray-700 mb-4">For Personal Visit (By Prior Appointment Only)</p>
            <p className="text-gray-700">Monday To Saturday</p>
            <p className="text-gray-700">9:00 AM To 2:00 PM & 4:00 PM To 8:00 PM</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AppointmentSection;
