// src/App.tsx

import React from 'react';
import Header from './components/Header';
import Navbar from './components/Navbar';
import TopBar from './components/TopBar';
import ServiceSlider from './components/ServiceSlider';

import './index.css'; // Ensure Tailwind styles are included
import SpecializedServicesSection from './components/SpecializedServicesSection';
import SanjayBhargavaSection from './components/SanjayBhargavaSection';
import AstrologySection from './components/AstrologySection';
import ServicesSection from './components/ServicesSection';
import ControlYourHusband from './components/ControlYourHusband';
import AwardsSection from './components/AwardsSection';
import TestimonialsSection from './components/TestimonialsSection';
import ParallaxSection from './components/ParallaxSection';
import AppointmentSection from './components/AppointmentSection';
import PaymentSection from './components/PaymentSection';
import OtherServicesSection from './components/OtherServicesSection';
import PaymentMethodSection from './components/PaymentMethodSection';
import QuoteModal from './components/QuoteModal';



const App: React.FC = () => {
  return (
    <div>
      <TopBar />
      <Header />
      <Navbar />
      <ServiceSlider />
      <SpecializedServicesSection />
      <AstrologySection />
    
     
      <ServicesSection />
      <SanjayBhargavaSection />
      <ControlYourHusband />
      <AwardsSection />
      <TestimonialsSection />
      <AppointmentSection />

      <ParallaxSection />
      <PaymentSection />
      {/* <OtherServicesSection /> */}

      <PaymentMethodSection />
      {/* <QuoteModal/> */}
      <main>
        {/* Your main content goes here */}
      </main>
    </div>
  );
};

export default App;
