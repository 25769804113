import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gradient-to-r from-purple-900 via-gray-900 to-black text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Column 1: About Manoj Shastri */}
          <div className="col-span-1 md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">About Manoj Shastri</h3>
            <p className="text-gray-300 mb-4 leading-7">
              Pandit Manoj Shastri ji is a renowned astrologer with excellence in astrology. He is the best astrologer because many of his predictions have come true, bringing positivity and solutions to many.
            </p>
          </div>

          {/* Column 2: Contact Information */}
          <div className="col-span-1 md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <p className="text-gray-300 leading-7">+91-9672315088</p>
       
            <p className="text-gray-300 leading-7">ptmanojshastrig@gmail.com</p>
            <p className="text-gray-300 leading-7">Global Hospital 
Vaishali Nagar ,
Jaipur</p>
          </div>

          {/* Column 3: Payment Method */}
          <div className="col-span-1 md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">Payment Method</h3>
            <p className="text-gray-300 leading-7">
              We Accept Payment Through:
            </p>
            <ul className="text-gray-300 leading-7 space-y-2">
              <li>Paytm</li>
              <li>PhonePe</li>
              <li>Google Pay</li>
              <li>Net Banking</li>
            </ul>
          </div>

          {/* Column 4: Additional Information */}
          <div className="col-span-1 md:col-span-1">
            <h3 className="text-lg font-semibold mb-4">About Manoj Shastri</h3>
            <p className="text-gray-300 leading-7">
              Pandit Manoj Shastri ji is a renowned astrologer with excellence in astrology. He is the best astrologer because many of his predictions have come true, bringing positivity and solutions to many.
            </p>
          </div>
        </div>

        {/* Footer Bottom */}
        <div className="border-t border-gray-700 mt-8 pt-8 text-center md:text-left">
          <p className="leading-7">&copy; 2024 Manoj Shastri. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
