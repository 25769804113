import React, { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="bg-dark shadow-sm sticky top-0 z-50">
      <div className="container mx-auto flex items-center justify-between py-2 px-4 md:px-8">
        <button
          className="text-white md:hidden"
          onClick={toggleNavbar}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
        <div className={`w-full md:flex md:items-center md:w-auto ${isOpen ? 'block' : 'hidden'}`}>
          <ul className="md:flex md:space-x-8">
            <li className="nav-item">
              <a className="nav-link text-light px-4 py-2" href="#">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light px-4 py-2" href="#">About</a>
            </li>
            <li className="nav-item relative">
              <a
                className="nav-link text-light px-4 py-2 flex items-center cursor-pointer"
                onClick={toggleDropdown}
              >
                Our Services <FiChevronDown className="ml-1" />
              </a>
              <ul className={`absolute left-0 mt-2 py-2 w-64 bg-white rounded-lg shadow-lg transition duration-300 ease-in-out transform ${isDropdownOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 invisible'}`}>
                <li><a className="dropdown-item block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100" href="#">Love Marriage Specialist</a></li>
                <li><a className="dropdown-item block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100" href="#">Love Problem Specialist</a></li>
                <li><a className="dropdown-item block px-6 py-3 text-lg text-gray-700 hover:bg-gray-100" href="#">Husband Wife Problems</a></li>
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light px-4 py-2" href="#">Achievements & Awards</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light px-4 py-2" href="#">Client Reviews</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light px-4 py-2" href="#">FAQ</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-light px-4 py-2" href="#">Contact Us</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
