import React from 'react';

const ServicesSection: React.FC = () => {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            OUR SERVICES
          </h2>
          <p className="text-xl text-gray-700">
            We Have Best & Life Time Solutions
          </p>
          <p className="text-lg text-gray-600 mt-2">
            We are committed to helping people in need. We offer a number of astrological services that have long-lasting results and improve life by eliminating challenges.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {[
            { title: 'BLACK MAGIC', description: 'Remove negativity from your life', img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuDOgnqbC8kktbsb2BJLoOUJaXyLQwoN80Jw&s', linkText: 'Call Now' },
            { title: 'LOVE SPELLS', description: 'Charm your desired partner', img: 'https://garywilliamsparanormal.com/wp-content/uploads/love-carefree-PE2AH8U-990x1024.jpg', linkText: 'Call Now' },
            { title: 'FAMILY PROBLEMS', description: 'Bring happiness in family', img: 'https://st3.depositphotos.com/11432552/14337/i/450/depositphotos_143373881-stock-photo-family-having-conflict.jpg', linkText: 'Call Now' },
            { title: 'DIVORCE PROBLEMS', description: 'Stop separation with your partner', img: 'https://www.dcomply.com/wp-content/uploads/2021/02/divorce-mistakes.jpg', linkText: 'Call Now' },
            { title: 'IMPROVE RELATIONS', description: 'Solve the bitterness in relation', img: 'https://us.res.keymedia.com/files/image/iStock-team_914776002%20(500%20x%20334).jpg', linkText: 'Call Now' },
            { title: 'EVIL ENERGY', description: 'Remove magic spells & curses', img: 'https://w0.peakpx.com/wallpaper/281/620/HD-wallpaper-well-of-darkness-abstract-dark-evil-fractal-fragment-gothic.jpg', linkText: 'Call Now' },
            { title: 'LOVE BACK', description: 'Persuade your love to come back', img: 'https://media.licdn.com/dms/image/D4D12AQGFjSmZODSUCA/article-cover_image-shrink_720_1280/0/1672320568860?e=2147483647&v=beta&t=qph6IfOpa2pCHViOsnVmSY11hnTzMQXxVrn0KGZsS3M', linkText: 'Call Now' },
            { title: 'DELAY MARRIAGE', description: 'Know about delay in marriage', img: 'https://astrotalk.com/astrology-blog/wp-content/uploads/2021/12/Remedies-for-marriage-delay.jpg', linkText: 'Call Now' },
          ].map((service, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img src={service.img} alt={service.title} className="w-full h-32 object-cover mb-4 rounded-lg" />
              <h3 className="text-xl font-bold mb-2 text-red-500">{service.title}</h3>
              <p className="text-gray-700 mb-4">{service.description}</p>
              <a href="#" className="block text-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300">
                {service.linkText}
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
