import React, { useState, useEffect } from 'react';
import pandit from "../images/pandit.png"; // Ensure this path is correct based on your project structure
import loveProblemSpecialist from "../images/love-marriage-specialist1.png"; // Ensure this path is correct based on your project structure

interface Slide {
  imageUrl: string;
  title: string;
  description: string;
}

const slides: Slide[] = [
  {
    imageUrl: 'https://ucarecdn.com/fdc190ee-98c5-4e5e-86f4-17b36c3fff18/-/preview/1000x626/',
    title: 'Pandit Manoj Shastri',
    description: 'Are you facing challenges in your love marriage, dealing with love problems, or experiencing issues with negativity in your life? Pandit Manoj Shastri, an expert astrologer and renowned specialist, is here to provide you with the guidance and solutions you need. With extensive experience in astrology and a deep understanding of relationship dynamics, Pandit Manoj Shastri has helped countless individuals and couples find happiness and resolve their issues effectively.',
  },
  {
    imageUrl: 'https://ucarecdn.com/498d1af8-afb0-4ef2-b1aa-d3de0ff779b0/-/preview/1000x626/',
    title: 'Love Marriage Specialist',
    description: 'Inter-caste and inter-religion marriages can face unique challenges. Our specialist provides tailored solutions to bridge cultural gaps and foster mutual understanding and acceptance.',
  },
  {
    imageUrl: loveProblemSpecialist,
    title: 'Love Problem Specialist',
    description: 'Love marriages can sometimes encounter obstacles that require specialized knowledge and experience to overcome.',
  },
];

const Carousel = () => {
  const [currentSlide, setCurrentSlide] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const goToPrevSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
  };

  const goToNextSlide = () => {
    setCurrentSlide(prevSlide => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
  };

  return (
    <div id="default-carousel" className="relative w-full">
      {/* Carousel wrapper */}
      <div className="relative h-[200px] md:h-[700px] lg:h-screen overflow-hidden">
        {/* Slides */}
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute inset-0 flex items-center justify-center transition-opacity duration-700 ease-in-out ${
              currentSlide === index ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <div
              className="w-full h-full bg-cover bg-center relative"
              style={{ 
                backgroundImage: `url(${slide.imageUrl})`, 
                backgroundSize: 'cover', 
                backgroundPosition: 'center'
              }}
            >
              <div className="max-w-7xl mx-auto px-4 md:px-8 h-full flex items-center">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center">
                  {/* Left Side (Text) */}
                  <div className="flex flex-col justify-center text-center md:text-left text-white order-2 md:order-1 p-4 bg-black bg-opacity-50 md:bg-opacity-0 md:p-0 rounded-lg md:rounded-none">
                    <h2 className="text-lg md:text-3xl lg:text-5xl font-extrabold mb-2 md:mb-4">{slide.title}</h2>
                    <p className="text-xs md:text-lg lg:text-xl hidden md:block">{slide.description}</p>
                    <p className="text-xs md:text-lg lg:text-xl md:hidden">{slide.description.substring(0, 100)}...</p>
                    {index === 2 && (
                      <p className="text-xs md:text-lg lg:text-xl mt-2">MEET WITH FAMOUS INDIAN ASTROLOGER</p>
                    )}
                  </div>
                  {/* Right Side (Pandit Image for first slide) */}
                  {index === 0 && (
                    <div className="flex justify-center md:justify-end items-center md:relative order-1 md:order-2 mt-4 md:mt-0">
                      <img
                        src={pandit}
                        alt="Pandit Image"
                        className="w-16 h-16 md:w-auto md:h-auto md:rounded-lg animate__animated animate__heartBeat animate__infinite"
                        style={{ border: 'none' }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Slider controls */}
      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 left-2 md:left-8 z-10 flex items-center justify-center h-8 w-8 md:h-12 md:w-12 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
        data-carousel-prev
        onClick={goToPrevSlide}
      >
        <svg fill="#fff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
          <path id="XMLID_6_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M205.606,234.394 c5.858,5.857,5.858,15.355,0,21.213C202.678,258.535,198.839,260,195,260s-7.678-1.464-10.606-4.394l-80-79.998 c-2.813-2.813-4.394-6.628-4.394-10.606c0-3.978,1.58-7.794,4.394-10.607l80-80.002c5.857-5.858,15.355-5.858,21.213,0 c5.858,5.857,5.858,15.355,0,21.213l-69.393,69.396L205.606,234.394z"/>
        </svg>
        <span className="sr-only">Previous</span>
      </button>

      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 right-2 md:right-8 z-10 flex items-center justify-center h-8 w-8 md:h-12 md:w-12 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none"
        data-carousel-next
        onClick={goToNextSlide}
      >
        <svg fill="#fff" height="800px" width="800px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330">
          <path id="XMLID_2_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M225.606,175.605 l-80,80.002C142.678,258.535,138.839,260,135,260s-7.678-1.464-10.606-4.394c-5.858-5.857-5.858-15.355,0-21.213l69.393-69.396 l-69.393-69.392c-5.858-5.857-5.858-15.355,0-21.213c5.857-5.858,15.355-5.858,21.213,0l80,79.998 c2.814,2.813,4.394,6.628,4.394,10.606C230,168.976,228.42,172.792,225.606,175.605z"/>
        </svg>
        <span className="sr-only">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
