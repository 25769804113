import React, { useState } from 'react';
import axios from 'axios';

const QuoteModal: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    dob: '',
    maritalStatus: '',
    city: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    dob: '',
    maritalStatus: '',
    city: ''
  });

  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleCloseModal = () => {
    const modal = document.getElementById('getQuoteModal');
    if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      modal.setAttribute('aria-hidden', 'true');
    }
    setSuccessMessage('');
    setErrorMessage('');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '', email: '', dob: '', maritalStatus: '', city: '' };

    if (!formData.name) {
      newErrors.name = 'Name is required';
      valid = false;
    }

    if (!formData.email) {
      newErrors.email = 'Email is required';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
      valid = false;
    }

    if (!formData.dob) {
      newErrors.dob = 'Date of Birth is required';
      valid = false;
    }

    if (!formData.maritalStatus) {
      newErrors.maritalStatus = 'Marital Status is required';
      valid = false;
    }

    if (!formData.city) {
      newErrors.city = 'City is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (validateForm()) {
      setLoading(true);
      setErrorMessage('');
      try {
        const response = await axios.post('https://astrologermanojshastri.com/api/sendMail.php', formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.status === 200) {
          setSuccessMessage('Email sent successfully');
          setFormData({
            name: '',
            email: '',
            dob: '',
            maritalStatus: '',
            city: ''
          });
        } else {
          setErrorMessage('Failed to send email');
        }
      } catch (error) {
        setErrorMessage('An error occurred while sending email');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="modal fade show" id="getQuoteModal" tabIndex={-1} aria-labelledby="getQuoteModalLabel" aria-hidden="true" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="getQuoteModalLabel">Get a Quote</h5>
            <button type="button" className="btn-close" onClick={handleCloseModal} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            {successMessage && <div className="alert alert-success">{successMessage}</div>}
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
            <form>
              <div className="mb-3">
                <label htmlFor="name" className="form-label">Name</label>
                <input type="text" className="form-control" id="name" value={formData.name} onChange={handleChange} required />
                {errors.name && <div className="text-danger">{errors.name}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">Email</label>
                <input type="email" className="form-control" id="email" value={formData.email} onChange={handleChange} required />
                {errors.email && <div className="text-danger">{errors.email}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="dob" className="form-label">Date of Birth</label>
                <input type="date" className="form-control" id="dob" value={formData.dob} onChange={handleChange} required />
                {errors.dob && <div className="text-danger">{errors.dob}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="maritalStatus" className="form-label">Marital Status</label>
                <select className="form-select" id="maritalStatus" value={formData.maritalStatus} onChange={handleChange} required>
                  <option value="">Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                </select>
                {errors.maritalStatus && <div className="text-danger">{errors.maritalStatus}</div>}
              </div>
              <div className="mb-3">
                <label htmlFor="city" className="form-label">City</label>
                <input type="text" className="form-control" id="city" value={formData.city} onChange={handleChange} required />
                {errors.city && <div className="text-danger">{errors.city}</div>}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteModal;