import React, { useState } from "react";
import logo from "../images/pandit-logo.png"; // Ensure the path is correct
import QuoteModal from "./QuoteModal";

const Header: React.FC = () => {
  const [showQuote, setShowQuote] = useState(false);

  return (
    <header className="bg-white shadow-md border-b-2 border-red-500">
      <div className="container mx-auto flex items-center justify-between py-2 px-4 md:px-0">
        <div className="flex flex-1 justify-center md:justify-start">
          <img
            src={logo}
            alt="Logo"
            className="h-12 w-auto object-cover md:h-16"
            style={{ maxHeight: "3.5rem" }} // Adjust max height for mobile
          />
        </div>
        <div className="hidden md:block">
          {/* Show only on medium and larger screens */}
          <button
            onClick={() => setShowQuote(true)}
            className="bg-red-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-red-600 transition duration-300 text-xs md:text-sm lg:text-base"
          >
            Book Appointment
          </button>
        </div>
      </div>
      {/* Mobile View: Show button at bottom */}
      <div className="block md:hidden py-2 px-4 flex justify-center">
        <button
          onClick={() => setShowQuote(true)}
          className="bg-red-500 text-white py-2 px-4 rounded-full shadow-md hover:bg-red-600 transition duration-300 text-xs"
        >
          Book Appointment
        </button>
      </div>
      {showQuote && <QuoteModal />}
    </header>
  );
};

export default Header;
