import React from 'react';

const AwardsSection: React.FC = () => {
  const awards = [
    {
      heading: "Award 1",
      content: "Description of Award 1",
      image: "https://img.freepik.com/free-vector/realistic-film-awards-illustration_23-2149314769.jpg?size=338&ext=jpg&ga=GA1.1.1546980028.1719705600&semt=ais_user",
    },
    {
      heading: "Award 2",
      content: "Description of Award 2",
      image: "https://img.freepik.com/free-vector/realistic-film-awards-illustration_52683-82924.jpg?t=st=1720525439~exp=1720526039~hmac=0a55c2a3c54fe96fac7f01fe0220ffd225e676ef5dbc66e6edff8542313517fc",
    },
    {
      heading: "Award 3",
      content: "Description of Award 3",
      image: "https://www.awardsohio.com/cdn/shop/files/Trophy_Slide_1-Mobile.webp?v=1683478904&width=1458",
    },
    {
      heading: "Award 4",
      content: "Description of Award 4",
      image: "https://www.awardsohio.com/cdn/shop/files/Trophy_Slide_1-Mobile.webp?v=1683478904&width=1458",
    },
  ];

  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto mb-8 text-center">
          <h2 className="text-3xl font-bold inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight mb-4">
            PANDIT JI VICTORIES
          </h2>
          <p className="text-lg text-gray-800 mb-8">
            Awards And Achievements<br />
            Know about the awards and accomplishments of astrologers which are offered by the most famous astrology organizations for their works for the human welfare.
          </p>
        </div>
        <div className="grid gap-8 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          {awards.map((award, index) => (
            <div key={index} className="flex flex-col items-center bg-white rounded-lg shadow-lg overflow-hidden">
              <img src={award.image} alt={award.heading} className="w-full h-auto" style={{ maxHeight: '200px' }} />
              <div className="p-4 text-center">
                <h3 className="text-xl lg:text-2xl font-bold text-red-500 mb-2">{award.heading}</h3>
                <p className="text-gray-700">{award.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AwardsSection;
