import React from 'react';
import { FiPhone } from 'react-icons/fi'; // Import phone icon from react-icons library

const PaymentSection = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-3xl font-bold inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight mb-4">
            CONSULTING CHARGES
          </h2>
          <p className="text-lg mb-8 text-gray-800">Select a package to proceed with your payment.</p>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center gap-8">
          {/* Card 1: RS. 501/- */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden border-t-4 border-red-500 transform transition duration-500 hover:scale-105 hover:shadow-xl">
            <div className="p-6">
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 bg-gradient-to-r from-red-500 to-red-700 text-white rounded-full flex items-center justify-center mr-3">
                  <FiPhone className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-700 mb-1">Call Us : +91-9672315088</span>
                </div>
              </div>
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-4 text-red-600">RS. 501/-</h3>
                <button className="bg-gradient-to-r from-red-500 to-red-700 text-white py-2 px-6 rounded-full border border-red-500 hover:bg-gradient-to-r hover:from-red-700 hover:to-red-900 transition duration-300 ease-in-out">
                  PAY NOW
                </button>
              </div>
            </div>
            <p className="text-gray-600 text-sm p-6 border-t border-gray-200">
              The safer, easier way to pay
              <br />
              <span className="text-red-500">
                NOTE: Beware From Fake Name Or Website. We have No Any Other Branch So Be Careful We Are Not Responsible For An Inconvenience
              </span>
            </p>
          </div>
          {/* Card 2: RS. 1100/- */}
          <div className="bg-white rounded-lg shadow-lg overflow-hidden border-t-4 border-red-500 transform transition duration-500 hover:scale-105 hover:shadow-xl">
            <div className="p-6">
              <div className="flex items-center mb-4">
                <div className="w-10 h-10 bg-gradient-to-r from-red-500 to-red-700 text-white rounded-full flex items-center justify-center mr-3">
                  <FiPhone className="w-6 h-6" />
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-700 mb-1">Call Us : +91-9672315088</span>
                </div>
              </div>
              <div className="text-center">
                <h3 className="text-2xl font-semibold mb-4 text-red-600">RS. 1100/-</h3>
                <button className="bg-gradient-to-r from-red-500 to-red-700 text-white py-2 px-6 rounded-full border border-red-500 hover:bg-gradient-to-r hover:from-red-700 hover:to-red-900 transition duration-300 ease-in-out">
                  PAY NOW
                </button>
              </div>
            </div>
            <p className="text-gray-600 text-sm p-6 border-t border-gray-200">
              The safer, easier way to pay
              <br />
              <span className="text-red-500">
                NOTE: Beware From Fake Name Or Website. We have No Any Other Branch So Be Careful We Are Not Responsible For An Inconvenience
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentSection;
