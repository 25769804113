import React from 'react';

const AstrologySection: React.FC = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-purple-100 via-red-200 to-yellow-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Discover the Power of Astrology
          </h2>
          <p className="text-xl text-gray-700">
            Unlock profound insights and solutions for a harmonious life through astrology.
          </p>
        </div>
        <div className="bg-white p-8 rounded-lg shadow-xl border-4 border-gradient-to-r from-red-500 to-black">
          <h3 className="text-3xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Meet Pandit Manoj Shastri
          </h3>
          <p className="text-lg text-gray-700 mb-6">
            With a deep understanding of celestial sciences, Pandit Manoj Shastri has earned international recognition as a leading astrologer. His mission is to provide accurate predictions and effective remedies to help individuals lead fulfilling lives.
          </p>
          <ul className="list-disc pl-6 mb-6 space-y-2 text-lg text-gray-700">
            <li>Offering transformative insights and guidance</li>
            <li>Specializes in love, career, and personal growth</li>
            <li>Known for accurate predictions and powerful remedies</li>
            <li>Dedicated to improving lives through astrology</li>
          </ul>
          <h4 className="text-2xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Trusted by Many
          </h4>
          <p className="text-lg text-gray-700 mb-6">
            Pandit Manoj Shastri’s credibility comes from his consistent results. Countless individuals have found peace and solutions to their life challenges through his insightful astrological guidance.
          </p>
          <p className="text-lg text-gray-700 mb-6 italic">
            "Astrology is not just a science; it’s a path to living a harmonious life."
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Many skeptics have turned into believers after experiencing the profound impact of astrology in their lives. With Pandit Manoj Shastri’s expertise, navigating life’s complexities becomes easier and more fulfilling.
          </p>
          <h4 className="text-2xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Comprehensive Astrological Services
          </h4>
          <p className="text-lg text-gray-700 mb-6">
            From personalized horoscopes to powerful remedies, discover how astrology can illuminate your path and bring clarity to your decisions. Consult with Pandit Manoj Shastri to embark on a journey towards a harmonious and prosperous life.
          </p>
          <p className="text-lg text-gray-700 mb-6">
            Embrace the transformative power of astrology and experience a positive shift in your life today.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AstrologySection;
