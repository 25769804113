import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ControlYourHusband: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
  };

  const slides = [
    {
      heading: "Control Your Husband",
      content: "LEARN HOW TO CONTROL YOUR HUSBAND. Your husband does not listen to you! Get an astrological solution now to control your husband. Astrology can help to bring change in his nature and make him love you.",
      image: "https://img.freepik.com/free-photo/adult-traveler-couple-unpleased-man-wearing-headphones-neck-holding-suitcase-sad-woman-pulling-his-hand-begging-him-both-looking-each-other-isolated_141793-68615.jpg?t=st=1719314424~exp=1719318024~hmac=4b446168612fe8373fb048ea86f07cfdc8400de4c2069f97ee9bc60899f2e1e6&w=996",
    },
    {
      heading: "HOW TO GET BACK YOUR EX GIRLFRIEND",
      content: "Avail the best astrological solution, which makes it easy for you to handle your relationship problems. Remove the differences, bring love into a relationship, and keep it for life long.",
      image: "https://img.freepik.com/free-photo/angry-girlfriend-staying-her-boyfriend_23-2148397706.jpg?t=st=1719314790~exp=1719318390~hmac=5047a7bdb39a09fe126ef680351910de4c5ce6287166c576efc72e58fc77fae1&w=900",
    },
    {
      heading: "MARRY WITH YOUR DESIRED PARTNER",
      content: "Are you going to marry! Match Your Kundli for compatibility check now. This will make the bride and groom know whether they are able to overcome challenges after marriage.",
      image: "https://img.freepik.com/free-photo/hands-indian-bride-groom-intertwined-together-making-authentic-wedding-ritual_8353-10047.jpg?t=st=1719314539~exp=1719318139~hmac=1791837e7204dd43ee5cea42a26d3a673f97bf8978209c94808af57390c02903&w=900",
    },
    {
      heading: "Match Your Kundli",
      content: "MARRY WITH YOUR DESIRED PARTNER. Are you going to marry! Match Your Kundli for compatibility check now. This will make the bride and groom know whether they are able to overcome challenges after marriage.",
      image: "https://img.freepik.com/free-photo/numerology-collage-concept_23-2150061748.jpg?t=st=1719314737~exp=1719318337~hmac=4cdd91cafa1b98cb27ce9187ed5e39ff4a4f7ccfb9da16ad7988ab64a2a9742e&w=996",
    },
  ];

  return (
    <section className="relative h-auto overflow-hidden py-8">
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center z-0"
        style={{ backgroundImage: `url('https://img.freepik.com/free-photo/space-background-with-stardust-shining-stars-realistic-colorful-cosmos-with-nebula-milky-way_1258-150925.jpg?t=st=1719313749~exp=1719317349~hmac=45de28e5a2ed15551fdf96330522e28ff837d7d161fc8d4c63599b628362817a&w=740')` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      </div>

      {/* Slider Content */}
      <div className="relative z-10 flex items-center justify-center">
        <div className="container mx-auto px-4">
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index} className="flex items-center justify-center h-full">
                <div className="text-center text-white p-6 rounded-lg shadow-lg bg-opacity-90 bg-gray-800">
                  <img
                    src={slide.image}
                    alt={slide.heading}
                    className="mx-auto mb-4 rounded-md border-2 border-yellow-500 max-w-full h-auto"
                    style={{ maxHeight: '150px' }}
                  />
                  <h2 className="text-3xl lg:text-4xl font-bold text-yellow-500 mb-4">{slide.heading}</h2>
                  <p className="text-lg text-gray-300 leading-relaxed mb-4">{slide.content}</p>
                  <a href="#" className="inline-block mt-4 px-6 py-3 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition duration-300">Learn More</a>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ControlYourHusband;
