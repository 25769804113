import React from 'react';
import "animate.css/animate.css"; 

const SpecializedServicesSection: React.FC = () => {
  return (
    <section className="py-16 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Pandit Manoj Shastri Specialised In These Services
          </h2>
        </div>
        <p className="text-center text-gray-700 mb-12 animate__animated animate__fadeInLeft">
          We cater astrology services to people across the world. Our expert astrologer uses his knowledge to heal people and solve their problems.
        </p>
        
        <div className="flex flex-wrap -mx-4">
           {/* Card 3: Husband Wife Problems */}
           <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 animate__animated animate__fadeInRight h-full flex flex-col hover:border-b-4 hover:border-red-500 transition-all duration-300 ease-in-out">
              <img
                src="https://img.freepik.com/free-photo/i-can-t-believe-we-re-still-fighting-about-this-i-can-t-believe-he-said-that-couple-having-argument-home-unhappy-couple-having-argument-home_657921-1677.jpg?t=st=1719302890~exp=1719306490~hmac=dc4c47f732c58991a477f975382ef89017a628b7ba6ba4de35d883489e424d7b&w=900"
                alt="Husband Wife Problems"
                className="w-full h-40 object-cover"
              />
              <div className="p-6 flex flex-col flex-grow">
                <h3 className="text-xl font-bold mb-2">Husband Wife Problems</h3>
                <p className="text-gray-700 mb-4 flex-grow">
                  Resolve conflicts and misunderstandings between couples for a harmonious relationship.
                </p>
                <a
                  href="#"
                  className="block text-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          {/* Card 1: Love Marriage Specialist */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 animate__animated animate__fadeInRight h-full flex flex-col hover:border-b-4 hover:border-red-500 hover:border-b-4 transition-all duration-300 ease-in-out">
              <img
                src="https://img.freepik.com/free-photo/medium-shot-couple-crisis_23-2148542255.jpg?t=st=1719302788~exp=1719306388~hmac=54b1a2dfc26705f70d7b870e46a1bfdd6d8d0edab058c0ffc68302915d8feffa&w=1060"
                alt="Love Marriage Specialist"
                className="w-full h-40 object-cover"
              />
              <div className="p-6 flex flex-col flex-grow">
                <h3 className="text-xl font-bold mb-2">Love Marriage Specialist</h3>
                <p className="text-gray-700 mb-4 flex-grow">
                  Solve issues related to love marriage with expert guidance and solutions.
                </p>
                <a
                  href="#"
                  className="block text-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
          
          {/* Card 2: Love Problem Specialist */}
          <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-8">
            <div className="bg-white rounded-lg overflow-hidden shadow-lg transform hover:scale-105 transition duration-300 animate__animated animate__fadeInRight h-full flex flex-col hover:border-b-4 hover:border-red-500 transition-all duration-300 ease-in-out">
              <img
                src="https://img.freepik.com/free-photo/sad-couple-having-problems-relationship_1150-4913.jpg?t=st=1719302841~exp=1719306441~hmac=013d55b6acc87ec263f05ad6497206e15b64aa94d51899d5a9b84fc84a68d56a&w=900"
                alt="Love Problem Specialist"
                className="w-full h-40 object-cover"
              />
              <div className="p-6 flex flex-col flex-grow">
                <h3 className="text-xl font-bold mb-2">Love Problem Specialist</h3>
                <p className="text-gray-700 mb-4 flex-grow">
                  Get solutions for various love-related problems with personalized counseling.
                </p>
                <a
                  href="#"
                  className="block text-center bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition duration-300"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>

         
        </div>
      </div>
    </section>
  );
};

export default SpecializedServicesSection;
