import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TestimonialsSection: React.FC = () => {
  // Testimonials data
  const testimonials = [
    {
      id: 1,
      name: 'Rajesh Kumar',
      review:
        'Pandit Manoj Shastri’s guidance has been life-changing for me. His accurate predictions and effective remedies have helped me overcome numerous challenges.',
      rating: 5,
      image: 'https://randomuser.me/api/portraits/men/1.jpg',
    },
    {
      id: 2,
      name: 'Anita Sharma',
      review:
        'I was struggling with career decisions, but Pandit Manoj Shastri’s insights provided the clarity I needed. His knowledge and advice are unparalleled.',
      rating: 4.5,
      image: 'https://randomuser.me/api/portraits/women/2.jpg',
    },
    {
      id: 3,
      name: 'Suresh Verma',
      review:
        'Skeptical at first, I was amazed by Pandit Manoj Shastri’s accuracy. His remedies brought a positive shift in my life, and I now consult him regularly.',
      rating: 4.5,
      image: 'https://randomuser.me/api/portraits/men/3.jpg',
    },
    {
      id: 4,
      name: 'Priya Desai',
      review:
        'Pandit Manoj Shastri’s astrological expertise has been a guiding light for me. His profound knowledge and practical advice have been invaluable.',
      rating: 5,
      image: 'https://randomuser.me/api/portraits/women/4.jpg',
    },
  ];

  // Slick settings
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  // Custom arrow components
  const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      <button
        className={`${className} absolute top-1/2 left-0 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-lg`}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-gray-600"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M13.707 15.293a1 1 0 0 1-1.414 0L6.414 10l6.293-6.293a1 1 0 0 1 1.414 1.414L9.828 10l3.879 3.879a1 1 0 0 1 0 1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    );
  };

  const NextArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      <button
        className={`${className} absolute top-1/2 right-0 transform -translate-y-1/2 z-10 bg-white p-2 rounded-full shadow-lg`}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-8 w-8 text-gray-600"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M6.293 15.293a1 1 0 0 0 1.414 0L13.586 10 7.707 3.707a1 1 0 1 0-1.414 1.414L10.586 10l-4.293 4.293a1 1 0 0 0 0 1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
    );
  };

  return (
    <section className="relative py-16 bg-gradient-to-r from-red-500 to-black text-white">
      {/* Testimonials Content */}
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto mb-8 text-center">
          <h2 className="text-3xl font-bold animate__animated animate__fadeInRight mb-4">
            TESTIMONIALS
          </h2>
          <p className="text-lg mb-8">
            Read the honest reviews about our astrologer from clients around the world. His guaranteed solutions have helped people avail the best and most affordable services.
          </p>
        </div>
        {/* Testimonials Slider */}
        <Slider {...settings}>
          {testimonials.map((testimonial) => (
            <div key={testimonial.id} className="px-2">
              {/* Testimonial Card */}
              <div className="bg-white rounded-lg shadow-lg p-4 md:p-6 h-full flex flex-col justify-between">
                <div>
                  <div className="flex items-center mb-4">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-10 h-10 object-cover rounded-full mr-4"
                    />
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800">{testimonial.name}</h3>
                      <div className="flex items-center mt-1">
                        {/* Rating Stars */}
                        <span className="text-yellow-400 flex">
                          {Array.from({ length: Math.floor(testimonial.rating) }, (_, index) => (
                            <svg
                              key={index}
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4 mr-1"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 1a1 1 0 0 1 .785.376l2.56 3.252 4.29.627a1 1 0 0 1 .553 1.705l-3.096 3.168.732 4.487a1 1 0 0 1-1.451 1.054L10 15.254l-3.827 2.006a1 1 0 0 1-1.451-1.054l.732-4.487L.822 6.98a1 1 0 0 1 .553-1.705l4.29-.627L9.215 1.376A1 1 0 0 1 10 1zm0 7a1 1 0 0 1 .293.045l3.097.45a1 1 0 0 1 .553 1.705l-2.24 2.29.529 3.246a1 1 0 0 1-.334.899l-2.486 2.603 1.229-7.525a1 1 0 0 1 .29-.544l3.154-3.226zM10 3.654a1 1 0 0 1 .295.044l2.366.345-.428 2.617a1 1 0 0 1-.285.544l-1.928 1.974 1.133 6.935L10 13.265l-2.172 1.14 1.133-6.935-1.928-1.974a1 1 0 0 1-.285-.544l-.428-2.617 2.366-.345A1 1 0 0 1 10 3.654z"
                                clipRule="evenodd"
                              />
                            </svg>
                          ))}
                        </span>
                        <span className="text-gray-500 ml-1">{testimonial.rating}</span>
                      </div>
                    </div>
                  </div>
                  {/* Review Text */}
                  <p className="text-gray-700 text-sm md:text-base">{testimonial.review}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default TestimonialsSection;
