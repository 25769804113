import React from 'react';

const AstrologySection: React.FC = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-red-100 to-yellow-100">
      <div className="container mx-auto px-4">
        <div className="bg-white p-8 rounded-lg shadow-xl">
          <h3 className="text-2xl md:text-4xl lg:text-6xl text-center font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Pandit Manoj Shastri
          </h3>
          <p className="text-sm md:text-lg text-gray-700 mb-6">
            Becoming an astrologer is not easy; it requires a deep interest in celestial bodies. Pandit Manoj Shastri is a famous astrologer with a profound interest in astrology, admired across the world for his expertise. He aims to serve humanity using astrology.
          </p>
          <ul className="list-disc pl-6 mb-6 space-y-2 text-sm md:text-lg text-gray-700">
            <li>Predicting future events</li>
            <li>Helping to handle life’s challenges</li>
            <li>Providing guidance for making better life decisions</li>
            <li>Building trust through accurate predictions and solutions</li>
          </ul>
          <h4 className="text-xl md:text-2xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Renowned Astrologer in India
          </h4>
          <p className="text-sm md:text-lg text-gray-700 mb-6">
            The skills and satisfied clients have made Pandit Manoj Shastri a renowned astrologer. People trust him because his remedies consistently yield desired results.
          </p>
          <p className="text-sm md:text-lg text-gray-700 mb-6 italic">
            "Astrology is the approach to living a smooth and fulfilling life."
          </p>
          <p className="text-sm md:text-lg text-gray-700 mb-6">
            If anyone considers astrology a myth or superstition, it is not. Many people have benefited from astrology through Pandit Manoj Shastri ji.
          </p>
          <p className="text-sm md:text-lg text-gray-700 mb-6">
            People prefer accurate astrology predictions that are worth using. One can manage life's challenges, make life smooth, and have a positive perspective towards life using astrology. Ancient people used astrology, and many still believe in it.
          </p>
          <h4 className="text-xl md:text-2xl font-bold mb-4 inline-block bg-gradient-to-r from-red-500 to-black text-transparent bg-clip-text animate__animated animate__fadeInRight">
            Horoscope Making and Reading Service
          </h4>
          <p className="text-sm md:text-lg text-gray-700 mb-6">
            The horoscope reveals the planetary positions. Pandit Manoj Shastri ji creates horoscopes that provide insights into one’s life. This is how he solves numerous problems for people.
          </p>
          <p className="text-sm md:text-lg text-gray-700 mb-6">
            Now, you can talk to the best astrologer and discuss your problems. This way, you can find suitable solutions to your issues. Any life challenge can be overcome by consulting with an astrologer. Simple astrology remedies will surely transform your life in an easy way.
          </p>
        </div>
      </div>
    </section>
  );
};

export default AstrologySection;
