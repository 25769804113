// src/components/TopBar.tsx

import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';

const TopBar: React.FC = () => {
  return (
    <div className="bg-red-500 py-2">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between text-white text-sm space-y-2 md:space-y-0">
        <div className="flex items-center space-x-4 order-2 md:order-1">
          <a href="#" className="text-white hover:text-gray-300">
            <FaFacebook className="text-2xl" />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <FaTwitter className="text-2xl" />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <FaInstagram className="text-2xl" />
          </a>
          <a href="#" className="text-white hover:text-gray-300">
            <FaLinkedin className="text-2xl" />
          </a>
        </div>
        <div className="order-1 md:order-2">
          Contact us: +91-9672315088
        </div>
      </div>
    </div>
  );
};

export default TopBar;
