import React from 'react';

const ParallaxSection = () => {
  return (
    <section className="relative py-20">
      {/* Background Parallax Effect */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center"
        style={{
          backgroundImage: 'url(https://img.freepik.com/premium-photo/astrology-horoscope-pattern-texture-background-graphic-design_41691-2496.jpg)',
          backgroundAttachment: 'fixed',
        }}
      />
      {/* Content */}
      <div className="relative z-10 container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center text-white">
          <h2 className="text-4xl font-bold mb-4">
            IT'S A HIGH TIME TO SOLVE YOUR ALL TROUBLES
          </h2>
          <p className="text-lg mb-8">
            Stop being a problem for a long time. Come and take astrological solutions. Get the best and most affordable astrological remedies that will help you to remove unnecessary troubles in your life. Get in touch with an astrologer and take a solution.
          </p>
          {/* Call to Action Button */}
          <div className="flex justify-center">
            <a
              href="#contact"
              className="bg-red-500 hover:bg-red-600 text-white font-semibold py-3 px-6 rounded-lg shadow-md transition duration-300 ease-in-out"
            >
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ParallaxSection;
